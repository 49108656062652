import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, BooksWriting, Pagination } from "../../components"

const WritingBooksPage = ({ data, pageContext }) => {
  const {
    allContentfulWritingBook: { nodes: books },
  } = data

  // console.log("poems.js data", data)
  // console.log("poems.js pagecontext", pageContext)

  return (
    <Wrapper>
      <Layout>
        <BooksWriting
          title="Összes írás (könyvek)"
          books={books}
          page
          link="writing"
        />
        <Pagination pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  ${"" /* background from Poems.js  */}
  background: var(--clr-dark-5);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query WritingBooksQuery($skip: Int, $limit: Int) {
    allContentfulWritingBook(
      limit: $limit
      skip: $skip
      sort: { fields: writingBookPublished, order: DESC }
    ) {
      nodes {
        contentful_id
        writingBookTitle
        author {
          authorFullName
        }
        publisher {
          publisherName
        }
        writingBookPublished
        writingBookSize
        writingBookImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        }
        writingBookText {
          childMarkdownRemark {
            html
            excerpt(format: HTML, pruneLength: 100)
          }
        }
      }
    }
  }
`

export default WritingBooksPage
